export const data = [
	{
		to: 'Home',
		text: 'HEM',
	},
	{
		to: 'Services',
		text: 'TJÄNSTER',
	},
	{
		to: 'About',
		text: 'OM OSS',
	},
];