import React from 'react'
import {GlobalStyle} from './globalStyles'
import { Home } from './pages/Home';

function App() {
  return (
      <>
        <GlobalStyle/>
        <Home/>
      </>  
  );
}

export default App;
