import { Invandigt, Snickerier, Utvandigt, Tapetsering } from "./ContentData"

export const Data = [
    {
		text: 'Utvändigt',
	},
    {
		text: 'Invändigt',
	},
    {
		text: 'Tapetsering',
	},
    {
		text: 'Snickerier',
	}
	
]

export const ServicesData = [
	Utvandigt,
	Invandigt,
	Tapetsering,
	Snickerier
]